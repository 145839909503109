@import "../../assets/scss/main";

.Footer {
  background-size: auto;
  background-repeat: no-repeat;
  min-height: 100px;
  background-color: $primary;

  a {
    color: white!important;
  }
}
